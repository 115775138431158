import React, { Component } from 'react';
import { Button, Container, Grid, Icon, Message, Segment } from 'semantic-ui-react';

class Landing extends Component {
  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  }

  isTouchEnabled() {
  return ('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0);
}

  render() {
    return (
      <div className='animate'>
        <Grid.Column style={{ paddingTop: '0px' }}>
          <Container textAlign='left'>
            <h1>Willkommen!</h1>

            <p>Wir wollen im Rahmen unserer Forschung herausfinden, wie kleine und mittlere Unternehmen (KMU) sowie Kommunen ihre IT-Infrastruktur kosteng&uuml;nstig und unkompliziert absichern k&ouml;nnen. Denn leider ist IT-Sicherheit vor allem aus Kostengr&uuml;nden immer noch ein Thema, das in vielen Bereichen deutlich zu wenig Beachtung findet.</p>

            <h3>Was bieten wir Ihnen?</h3>
            <p>Wir m&ouml;chten daher KMUs und Kommunen die M&ouml;glichkeit geben, bis zu 5 extern erreichbare Systeme innerhalb eines Jahres kostenlos auf Schwachstellen zu scannen. Nach der Durchf&uuml;hrung des Scans senden wir Ihnen einen Bericht &uuml;ber die gefundenen Schwachstellen, um Sie bei der Sicherung Ihrer IT-Infrastruktur zu unterst&uuml;tzen. Im Gegenzug w&auml;ren wir sehr dankbar, wenn Sie uns Ihr Feedback hinterlassen w&uuml;rden!</p>

            <h3>Was sind die Voraussetzungen?</h3>
            <p>F&uuml;r unseren Service ist keine Registrierung erforderlich! Wir ben&ouml;tigen lediglich ihren Namen, ihre gesch&auml;ftliche Mail-Adresse sowie die Domains, die wir f&uuml;r Sie scannen sollen. Sie m&uuml;ssen innerhalb ihres Unternehmens/ihrer Kommune dazu berechtigt sein, Schwachstellenscans anordnen zu d&uuml;rfen. Deshalb fordern wir, dass Domains und Mail-Adresse &uuml;bereinstimmen. Beispiele f&uuml;r g&uuml;ltige Domains zur Mail-Adresse <b>john.doe@example.com</b> w&auml;ren <b>example.com</b>, aber auch Subdomains wie <b>mail.example.com</b>. Das Scannen von IP-Adressen wird &uuml;ber diesen Service aktuell nicht unterst&uuml;tzt.</p>
          </Container>
        </Grid.Column>
        <Segment>
          <p style={{ textAlign: 'center' }}><b>Fordern Sie jetzt Ihren Bericht in nur 3 Schritten an:</b></p>
          <p style={{ textAlign: 'center' }}>
            <Button positive onClick={this.saveAndContinue} style={{ textAlign: 'centered' }}>
              <Button.Content>
                <Icon name='rocket' size='big' />
                Los!
              </Button.Content>
            </Button>
          </p>
        </Segment>

        <Message info
          icon='exclamation circle'
          header='Sie brauchen einen Schwachstellenbericht f&uuml;r mehr als 5 Systeme?'
          content={
            <>
              <a href='mailto:scan@iese.de?subject=[Self Scan Service] Anfrage f&uuml;r ein individuelles Angebot'>Kontaktieren Sie uns</a> f&uuml;r mehr Infos!
            </>
          }
        />
      </div>
    )
  }
}

export default Landing;
