import React, { Component } from 'react';
import { Container, Image, Icon, Item, Menu } from 'semantic-ui-react';
import MetaTags from 'react-meta-tags';
import './App.css';
import MainForm from './components/MainForm';

class App extends Component {
  state = { visible: false };

  render() {
    return (
      <>
        {/* Header settings */}
        <div className="wrapper">
          <MetaTags>
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/manifest.json" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="apple-mobile-web-app-title" content="Self Scan Service" />
            <meta name="application-name" content="Self Scan Service" />
            <meta name="msapplication-TileColor" content="#1b1c1d" />
            <meta name="theme-color" content="#1b1c1d" />
            <title>Self Scan Service</title>
            <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0, viewport-fit=cover" />
          </MetaTags>
        </div>

        {/* Top menu bar */}
        <Menu fixed='top' inverted borderless style={{position: 'fixed'}}>
          <Container>
            <Menu.Item position='left'>
              <a href='/'>
                <Icon name='lock' size='large' circular/>
              </a>
              <a href='/'>
                <Image id='logo-icon' src="/self-scan-service-logo.png" />
              </a>
            </Menu.Item>
            <Menu.Item position='right'>
              <a href='https://iese.fraunhofer.de/' target='_blank' rel="noreferrer">
                <Image id='logo-icon2' src="/iese-white.png" />
              </a>
            </Menu.Item>
          </Container>
        </Menu>

        <div id='credit'>
          &copy; iStock.com/shulz
        </div>

        {/* Main content */}
        <Container id='cst-container'>
          <div id='cst-segment'>
            <MainForm notify={this.notify} />
          </div>
        </Container>

        {/* Footer bar */}
        

        <Menu fixed='bottom' inverted borderless id='footer-style'>
          <Menu.Item position="left">
          </Menu.Item>
          <Menu.Item>
            <Item.Content >
              &copy; {new Date().getFullYear()} <a className='subtitle-link' href='https://iese.fraunhofer.de/' target='_blank' rel="noreferrer">Fraunhofer IESE</a>
            </Item.Content>
          </Menu.Item>
          <Menu.Item>
            <Item.Content>
              <a className='subtitle-link' href='/imprint.htm' target='_blank' rel="noreferrer">
                Impressum
              </a>
            </Item.Content>
          </Menu.Item>
          <Menu.Item>
            <Item.Content>
              <a className='subtitle-link' href='/data-protection.htm' target='_blank' rel="noreferrer">
                Datenschutz
              </a>
            </Item.Content>
          </Menu.Item>
          <Menu.Item position="right">
          </Menu.Item>
        </Menu>
      </>
    );
  }
}

export default App;
