import React, { Component } from 'react';
import UserDetails from './UserDetails';
import AssetDetails from './AssetDetails';
import Confirmation from './Confirmation';
import Success from './Success';
import Landing from './Landing';
import Failure from './Failure';

import { Icon, Message } from 'semantic-ui-react';

import validator from 'validator';
import { isFQDN } from 'validator';
import { isIPAddress } from "ip-address-validator";
import { CSSTransition, SwitchTransition } from "react-transition-group";

class MainForm extends Component {
  state = {
    asset: '',
    assets: [],
    step: 0,
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    textField: '',
    scanAllowed: false,
    gdprAllowed: false,
    //AssetDetails
    visibleNoAssetsAdded: false,
    visibleIpsNotSupported: false,
    visibleDuplicateAsset: false,
    visibleNotFqdn: false,
    visibleTooManyAssets: true,
    //UserDetails
    visibleEmptyFirstname: false,
    visibleEmptyLastname: false,
    visibleNoValidMail: false,
    visibleMailsNotMatching: false,
    visibleDomainsNotMatching: false,
    // Confirmation
    visibleMissingCheckboxes: false,
    //General
    visibleTrigger: true,
  }

	nextStep = () => {
    const { step } = this.state
    this.setState({
      step : step + 1
    })
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState((prevState) => ({
      visibleNoAssetsAdded: false,
      visibleIpsNotSupported: false,
      visibleDuplicateAsset: false,
      visibleNotFqdn: false,
      visibleTooManyAssets: true,
      visibleEmptyFirstname: false,
      visibleEmptyLastname: false,
      visibleNoValidMail: false,
      visibleMailsNotMatching: false,
      visibleDomainsNotMatching: false,
      visibleMissingCheckboxes: false,
    }))
  }

  nextTwoSteps = () => {
    const { step } = this.state
    this.setState({
      step: step + 2
    })
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  prevStep = () => {
    const { step } = this.state
    this.setState({
      step : step - 1
    })
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState((prevState) => ({
      visibleNoAssetsAdded: false,
      visibleIpsNotSupported: false,
      visibleDuplicateAsset: false,
      visibleNotFqdn: false,
      visibleTooManyAssets: true,
      visibleEmptyFirstname: false,
      visibleEmptyLastname: false,
      visibleNoValidMail: false,
      visibleMailsNotMatching: false,
      visibleDomainsNotMatching: false,
      visibleMissingCheckboxes: false,
    }))
  }

  handleChange = input => event => {
    this.setState({[input]: event.target.value})
  }

  handleScanAllowed = (state) => {
    this.setState({ scanAllowed: state })
  };

  handleGdprAllowed = (state) => {
    this.setState({ gdprAllowed: state })
  };

  notify = () => {
    if (this.state.visibleNoAssetsAdded === true) {
      return (
        <Message error style={{ padding: '7px' }}>
          <>
            <Icon name='times circle' />
            Um fortzufahren, ben&ouml;tigen wir mindestens eine g&uuml;ltige Domain von Ihnen.
          </>
        </Message>
      );
    } else if (this.state.visibleIpsNotSupported === true) {
      return (
        <Message error style={{ padding: '7px' }}>
          <>
            <Icon name='times circle' />
            IP-Adressen sind nicht erlaubt. Bitte geben Sie eine g&uuml;ltige Domain an.
          </>
        </Message>
      );
    } else if (this.state.visibleDuplicateAsset === true) {
      return (
        <Message error style={{ padding: '7px' }}>
          <>
            <Icon name='times circle' />
            Sie haben bereits ein Asset mit dieser Domain hinzugef&uuml;gt.
          </>
        </Message>
      );
    } else if (this.state.visibleNotFqdn === true) {
      return (
        <Message error style={{ padding: '7px' }}>
          <>
            <Icon name='times circle' />
            Bitte geben Sie eine g&uuml;ltige Domain an.
          </>
        </Message>
      );
    } else if (this.state.visibleEmptyFirstname === true) {
      return (
        <Message error style={{ padding: '7px' }} >
          <>
            <Icon name='times circle' />
            Bitte geben Sie Ihren Vornamen an.
          </>
        </Message >
      );
    }
    else if (this.state.visibleEmptyLastname === true) {
      return (
        <Message error style={{ padding: '7px' }} >
          <>
            <Icon name='times circle' />
            Bitte geben Sie Ihren Nachnamen an.
          </>
        </Message >
      );
    } else if (this.state.visibleNoValidMail === true) {
      return (
        <Message error style={{ padding: '7px' }} >
          <>
            <Icon name='times circle' />
            Bitte geben Sie Ihre Mail-Adresse an.
          </>
        </Message >
      );
    } else if (this.state.visibleMailsNotMatching) {
      return (
        <Message error style={{ padding: '7px' }} >
          <>
            <Icon name='times circle' />
            Die Mail-Adressen, die Sie angegeben haben, stimmen nicht &uuml;berein.
          </>
        </Message >
      );
    } else if (this.state.visibleDomainsNotMatching) {
      return (
        <Message error style={{ padding: '7px' }} >
          <>
            <Icon name='times circle' />
            Alle Domains, die Sie angegeben haben, m&uuml;ssen zu Ihrer Mail-Adresse passen.
          </>
        </Message >
      );
    } else if (this.state.visibleMissingCheckboxes) {
      return (
        <Message error style={{ padding: '7px' }} >
          <>
            <Icon name='times circle' />
            Bitte stimmen Sie unseren Nutzungsbedingungen zu.
          </>
        </Message >
      );
    } else {
      return (<div></div>);
    }
  }

  handleAdd = () => {
    this.setState((prevState) => ({ visibleTrigger: !prevState.visibleTrigger }))
    this.setState({ visibleNoAssetsAdded: false })
    this.setState({ visibleIpsNotSupported: false })
    this.setState({ visibleNotFqdn: false })
    this.setState({ visibleDuplicateAsset: false })
    
    // is assets trunk full?
    if (this.state.assets.length >= 5) {
      this.setState((prevState) => ({ visibleTooManyAssets: !prevState.visibleTooManyAssets }))
      // is asset a non-IP address?
    } else if (isIPAddress(this.state.asset) === true) {
      this.setState({ visibleIpsNotSupported: true })
      // is asset a valid FQDN?
    } else if (isFQDN(this.state.asset)) {
      // check if we have duplicates
      const isMember = this.state.assets.filter(obj => {
        return obj === this.state.asset.toLowerCase();
      });
      // if there are no duplicates, go for it
      if (isMember.length === 0) {
        this.setState({
          assets: [...this.state.assets, this.state.asset.toLowerCase()],
        })
        this.setState({ visibleIpsNotSupported: false })
        this.setState({ visibleNotFqdn: false })
        this.setState({ visibleDuplicateAsset: false })
      } else {
        this.setState({ visibleDuplicateAsset: true })
      }
    } else {
      this.setState({ visibleNotFqdn: true })
    }
    this.setState({ asset: '' })
  }

  handleRemove = (asset) => {
    this.setState({
      assets: this.state.assets.filter(function (i) {
        return i !== asset
      })
    });
  }

  handleNext1 = () => {
    this.setState((prevState) => ({ visibleTrigger: !prevState.visibleTrigger }))
    this.setState({ visibleNoAssetsAdded: false })

    if (this.state.assets.length <= 0) {
      this.setState({ visibleNoAssetsAdded: true })
      return 0;
    }
    return 1;
  }

  handleNext2 = () => {
    this.setState((prevState) => ({ visibleTrigger: !prevState.visibleTrigger }))
    this.setState({ visibleNoValidMail: false })
    this.setState({ visibleMailsNotMatching: false })
    this.setState({ visibleDomainsNotMatching: false })
    this.setState({ visibleEmptyFirstname: false })
    this.setState({ visibleEmptyLastname: false })

    // is first name empty?
    if (validator.isEmpty(this.state.firstName)) {
      this.setState({ visibleEmptyFirstname: true })
    // is last name empty?
    } else if (validator.isEmpty(this.state.lastName)) {
      this.setState({ visibleEmptyLastname: true })
    // is mail and confirm mail valid email address?
    } else if (!validator.isEmail(this.state.email) || !validator.isEmail(this.state.confirmEmail)) {
      this.setState({ visibleNoValidMail: true })
    // do mail and confirm mail match?
    } else if (this.state.email !== this.state.confirmEmail) {
      this.setState({ visibleMailsNotMatching: true })
    } else {
      // do mail and all domains match?
      const emailSplitted = this.state.email.split('@');
      const mailBaseUrl = emailSplitted[1].toLowerCase();
      const assets = this.state.assets;
      for (let asset of assets) {
        if (asset.endsWith(mailBaseUrl) === false) {
          this.setState({ visibleDomainsNotMatching: true })
          //this.props.notify("This mail address does not match with the entered domain(s)!", "NO_HOST_MATCH", 'error')
          return 0;
        }
      }
      return 1;
    }
  }

  handleConfirm = () => {
    this.setState((prevState) => ({ visibleTrigger: !prevState.visibleTrigger }))
    this.setState({ visibleMissingCheckboxes: false })

    if (this.state.scanAllowed === false || this.state.gdprAllowed === false) {
      this.setState({ visibleMissingCheckboxes: true })
      return 0;
    }
    return 1;
  }

  renderSwitch(step) {
     switch (step) {
      case 1:
        return (
          <AssetDetails
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            handleAdd={this.handleAdd}
            handleRemove={this.handleRemove}
            values={this.state}
            notify={this.notify}
            handleNext1={this.handleNext1}
          />
        );
      case 2:
        return (
          <UserDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={this.state} 
            notify={this.notify}
            handleNext2={this.handleNext2}
          />
        );
      case 3:
        return (
          <Confirmation
            nextStep={this.nextStep}
            nextTwoSteps={this.nextTwoSteps}
            prevStep={this.prevStep}
            handleScanAllowed={this.handleScanAllowed}
            handleGdprAllowed={this.handleGdprAllowed}
            values={this.state}
            notify={this.notify}
            handleConfirm={this.handleConfirm}
          />
        );
      case 4:
        return (
          <Success/>
        );
      case 5:
        return (
          <Failure/>
        );
      default:
        return (
          <Landing
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={this.state}
            notify={this.notify}
          />
        );
    }
  }

  render() {
    return (
      <SwitchTransition>
        <CSSTransition
          key={this.state.step}
          addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
          classNames='fade'
        >
          {this.renderSwitch(this.state.step)}
        </CSSTransition>
      </SwitchTransition>
    )
  }
}

export default MainForm;
