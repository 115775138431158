import React, { Component } from 'react';
import { Container, Grid, Icon, Label, Message, Step } from 'semantic-ui-react';

class Success extends Component {
  render() {
    return (
      <div>
        <Grid.Column>
          <Container textAlign='center'>
            <Step.Group ordered widths={3}>
              <Step completed>
                <Step.Content>
                  <Step.Title>Domains</Step.Title>
                </Step.Content>
              </Step>

              <Step completed>
                <Step.Content>
                  <Step.Title>Nutzer</Step.Title>
                </Step.Content>
              </Step>

              <Step completed>
                <Step.Content>
                  <Step.Title>Best&auml;tigen</Step.Title>
                </Step.Content>
              </Step>
            </Step.Group>
            <Icon name='mail' size='huge'/>
            <h1>Schauen Sie in Ihr Postfach!</h1>
            <Label> Bitte vervollst&auml;ndigen Sie Ihre Anfrage, indem Sie auf den Link in der Best&auml;tigungsmail klicken.</Label>
          </Container>
        </Grid.Column>
        <Message info
          icon='exclamation circle'
          header='Sie brauchen einen Schwachstellenbericht f&uuml;r mehr als 5 Systeme?'
          content={
            <>
              <a href='mailto:scan@iese.de?subject=[Self Scan Service] Anfrage f&uuml;r ein individuelles Angebot'>Kontaktieren Sie uns</a> f&uuml;r mehr Infos!
            </>
          }
        />
            
      </div>
    )
  }
}

export default Success;
