import React, { Component } from 'react';
import { Button, Grid, Header, Icon, Label, List, Message, Step, Transition } from 'semantic-ui-react';
import { isFQDN } from 'validator';
import { isIPAddress } from "ip-address-validator";
import toast, { Toaster } from 'react-hot-toast';

class AssetDetails extends Component {
  saveAndContinue = (e) => {
    if (this.props.handleNext1() === 1) {
      this.props.nextStep();
    }
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        this.props.handleAdd();
      }
  }

  render() {
    const { values } = this.props;

    return (
      <div className='animate'>
        <Grid.Column>
          <Header textAlign='center'>
              <h1>Kostenloser Schwachstellenscan in 3 Schritten</h1>
          </Header>
          <Step.Group ordered widths={3}>
              <Step>
                  <Step.Content>
                      <Step.Title>Domains</Step.Title>
                  </Step.Content>
              </Step>

              <Step disabled>
                  <Step.Content>
                      <Step.Title>Nutzer</Step.Title>
                  </Step.Content>
              </Step>

              <Step disabled>
                  <Step.Content>
                  <Step.Title>Best&auml;tigen</Step.Title>
                  </Step.Content>
              </Step>
          </Step.Group>

          <div className="ui action input" style={{ width: '100%' }}>
              <input placeholder='Geben Sie bis zu 5 Domains an'
                onChange={this.props.handleChange('asset')}
                value={values.asset}
                onKeyPress={this.handleKeyPress}
              maxLength={40} />
              <Button onClick={this.props.handleAdd}>
                <Button.Content>
                  <Icon name='plus' />
                </Button.Content>
              </Button>
          </div>

          <Transition
            animation='shake'
            duration='100'
            visible={values.visibleTrigger}
          >
            {this.props.notify()}
          </Transition>

          <Transition.Group
              as={List}
              duration={200}
              divided
              size='huge'
              verticalAlign='middle'
              animation='slide down'
          >
              {values.assets.map((i) => (
                  <Label as='a' size='large' onClick={(event, data) => this.props.handleRemove(data.children[0])} style={{ margin: '2px' }}>
                      {i}
                      <Icon name='delete' />
                  </Label>
              ))}
          </Transition.Group>

        </Grid.Column>
        <p style={{ textAlign: 'center' }}>
          <Button positive floated='right' style={{ marginBottom: '10px', marginTop: '10px' }} onClick={this.saveAndContinue}>
            <Button.Content>
              <Icon name='arrow right' />
            </Button.Content>
          </Button>
        </p>

        <Transition
          animation='shake'
          duration='100'
          visible={values.visibleTooManyAssets}
        >
          <Message info
            icon='exclamation circle'
            header='Sie brauchen einen Schwachstellenbericht f&uuml;r mehr als 5 Systeme?'
            content={
              <>
                <a href='mailto:scan@iese.de?subject=[Self Scan Service] Anfrage f&uuml;r ein individuelles Angebot'>Kontaktieren Sie uns</a> f&uuml;r mehr Infos!
              </>
            }
          />
        </Transition>
      </div>
    )}
}

export default AssetDetails;
