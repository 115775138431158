import React, { Component } from 'react';
import { Button, Grid, Form, Icon, Message, Step, Transition } from 'semantic-ui-react';

class UserDetails extends Component {
  saveAndContinue = (e) => {
    e.preventDefault();
    if (this.props.handleNext2() === 1) {
      this.props.nextStep();
    }
  }
	
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  }

  render() {
    const { values } = this.props;

    return (
          <div>
        <Grid.Column>
          <Step.Group ordered widths={3}>
            <Step completed>
              <Step.Content>
                <Step.Title>Domains</Step.Title>
              </Step.Content>
            </Step>

            <Step>
              <Step.Content>
                <Step.Title>Nutzer</Step.Title>
              </Step.Content>
            </Step>

            <Step disabled>
              <Step.Content>
                <Step.Title>Best&auml;tigen</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>

              <Form>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <label>Vorname</label>
                    <input
                      placeholder='Vorname'
                      onChange={this.props.handleChange('firstName')}
                      defaultValue={values.firstName}
                      maxLength={250}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label>Nachname</label>
                    <input
                      placeholder='Nachname'
                      onChange={this.props.handleChange('lastName')}
                      defaultValue={values.lastName}
                      maxLength={250}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths='equal'>
                  <Form.Input
                    fluid
                    type='email'
                    label='Mail-Adresse'
                    placeholder='Mail-Adresse'
                    onChange={this.props.handleChange('email')}
                    defaultValue={values.email}
                    maxLength={250}
                  />

                  <Form.Input
                    onPaste={(e) => {
                      e.preventDefault()
                      return false;
                    }}
                    fluid
                    type='email'
                    label='Mail-Adresse best&auml;tigen'
                    placeholder='Mail-Adresse best&auml;tigen'
                    onChange={this.props.handleChange('confirmEmail')}
                    defaultValue={values.confirmEmail}
                    maxLength={250}
                  />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Input
                    fluid
                    label='Kommentare (optional)'
                    placeholder='Kommentare (optional)'
                    onChange={this.props.handleChange('textField')}
                    defaultValue={values.textField}
                    maxLength={250}
                  />
                </Form.Group>
              </Form>

              <Transition
                animation='shake'
                duration='100'
                visible={values.visibleTrigger}
              >
                {this.props.notify()}
              </Transition>

              <div>
                <Button onClick={this.back}>
                  <Button.Content>
                    <Icon name='arrow left' />
                  </Button.Content>
                </Button>

            <Button positive floated="right" onClick={this.saveAndContinue}>
                  <Button.Content>
                    <Icon name='arrow right' />
                  </Button.Content>
                </Button>
              </div>
            </Grid.Column>
            <Message info
              icon='exclamation circle'
              header='Sie brauchen einen Schwachstellenbericht f&uuml;r mehr als 5 Systeme?'
              content={
                <>
                  <a href='mailto:scan@iese.de?subject=[Self Scan Service] Anfrage f&uuml;r ein individuelles Angebot'>Kontaktieren Sie uns</a> f&uuml;r mehr Infos!
                </>
              }
            />
          </div>
          )}
}

export default UserDetails;
