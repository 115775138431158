import React, { Component } from 'react';
import { Container, Grid, Icon, Segment } from 'semantic-ui-react';

class Failure extends Component {
  render() {
    return (
      <div>
        <Grid.Column>
          <Container textAlign='center'>
            <Icon name='times circle' size='huge' color='red'/>
            <h1>Ein Fehler ist aufgetreten.</h1>
            <Segment>
              <p>Da ist wohl etwas schiefgelaufen:</p>
              <p><b>Vielleicht haben Sie in den letzten 365 Tagen bereits einen Scan angefordert?</b></p>
              <p><a href='mailto:scan@iese.de?subject=[Self Scan Service] Es gab Probleme bei meinem Scan'>Kontaktieren Sie uns</a> und wir finden gemeinsam eine L&ouml;sung.</p>
            </Segment>
          </Container>
        </Grid.Column>  
      </div>
    )
  }
}

export default Failure;
