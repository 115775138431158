import axios from 'axios';
import React, { Component } from 'react';
import { Button, Checkbox, Grid, Icon, Label, List, Message, Step, Transition } from 'semantic-ui-react';

class Confirmation extends Component {
	
	state = { 
		firstName: '',
		lastName: '', 
		email: '', 
		textField: '', 
		assets: [], 
		scanAllowed: false
  }

	
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.handleConfirm() === 1) {
      this.submitFormToServer(e);
    }
  }

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  }

  submitFormToServer = event => {
    event.preventDefault();

    let { values } = this.props;

    axios.post(`https://scan.iese.de/api/request/scan`, values,
      {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        console.log('Res status: ' + res.status);
        if (res.status === 200) {
          this.props.nextStep();
        } else {
          this.props.nextTwoSteps();
        }
      }, (error) => {
        this.props.nextTwoSteps();
    });
 
  }

  render() {
    const { values: { firstName, lastName, email, textField, assets, scanAllowed, gdprAllowed } } = this.props;
    return (
      <div>
        <Grid.Column>
          <Step.Group ordered widths={3}>
            <Step completed>
              <Step.Content>
                <Step.Title>Domains</Step.Title>
              </Step.Content>
            </Step>

            <Step completed>
              <Step.Content>
                <Step.Title>Nutzer</Step.Title>
              </Step.Content>
            </Step>

            <Step>
              <Step.Content>
                <Step.Title>Best&auml;tigen</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>

            <List divided relaxed>
              <List.Item>
                <List.Icon name='user' size='large' />
                <List.Content>
                  <List.Header>Name</List.Header>
                  <List.Description>{(firstName && lastName) ? (firstName + ' ' + lastName) : '-'}</List.Description>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Icon name='mail' size='large' />
              <List.Content>
                <List.Header>Mail-Adresse</List.Header>
                <List.Description>{email ? email : '-'}</List.Description>
              </List.Content>
              </List.Item>

              <List.Item>
                <List.Icon name='comment' size='large' />
                <List.Content>
                  <List.Header>Kommentare</List.Header>
                  <List.Description>{textField ? textField : '-'}</List.Description>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Icon name='server' size='large' />
                <List.Content>
                  <List.Header>Domains</List.Header>
                <List.Description>{assets ? 
                  assets.map((i) => (<Label style={{ margin: '2px' }}>{i}</Label>)) : '-'}</List.Description>
                </List.Content>
              </List.Item>
            </List>

          <div style={{ marginBottom: '15px' }}>
            <Checkbox style={{ paddingTop: '6px', marginRight: '5px' }}
              onClick={( event, data ) => this.props.handleScanAllowed(data.checked)}
            />
            <label>Ich best&auml;tige hiermit, dass ich befugt bin, Scans f&uuml;r die oben genannten Domains durchzuf&uuml;hren.
              Ich erteile dem Fraunhofer IESE ausdr&uuml;cklich die Erlaubnis, Scans in meinem Namen durchzuf&uuml;hren.</label>
          </div>

          <div style={{ marginBottom: '15px' }}>
            <Checkbox style={{ paddingTop: '6px', marginRight: '5px'}}
              onClick={(event, data) => this.props.handleGdprAllowed(data.checked)}>
            </Checkbox>
            <label>Ich bin mit der <a href='/data-protection.htm' target='_blank'>Datenschutzbestimmung</a> einverstanden.</label>
          </div>

          <Transition
            animation='shake'
            duration='100'
            visible={this.props.values.visibleTrigger}
          >
            {this.props.notify()}
          </Transition>

          <div>
            <Button onClick={this.back}>
              <Button.Content>
                <Icon name='arrow left' />
                Back
              </Button.Content>
            </Button>

            <Button positive floated="right" onClick={this.handleSubmit}>
              <Button.Content>
                <Icon name='check' />
                Confirm
              </Button.Content>
            </Button>
          </div>
        </Grid.Column>
        <Message info
          icon='exclamation circle'
          header='Sie brauchen einen Schwachstellenbericht f&uuml;r mehr als 5 Systeme?'
          content={
            <>
              <a href='mailto:scan@iese.de?subject=[Self Scan Service] Anfrage f&uuml;r ein individuelles Angebot'>Kontaktieren Sie uns</a> f&uuml;r mehr Infos!
            </>
          }
        />
      </div>
    )
  }
}

export default Confirmation;
